<template>
  <v-app>
    <div class="w-75">
      <v-form
        @submit="onSubmit"
        @reset="onReset"
      >
        <v-container>
          <v-row justify="center">
            <v-col
              :cols="$isMobile()? 12 : 6"
              :sm="$isMobile()? 12 : 6"
            >
              <v-card>
                <v-card-title class="align-center grey--text text--darken-2 px-4">
                  <v-img
                    src="@/assets/logo-arcus-trans.png"
                    height="80"
                    max-width="80"
                    contain
                  />
                  ARCUS - Milano
                </v-card-title>
                <v-card-subtitle class="mt-1">
                  Amministrazione
                </v-card-subtitle>
                <v-row justify="center">
                  <v-col
                    cols="10"
                    class="mx-2"
                  >
                    <v-text-field
                      id="email"
                      v-model="email"
                      :error-messages="emailErrors"
                      required
                      placeholder="email"
                      autocapitalize="off"
                      spellcheck="false"
                      autocorrect="off"
                      :disabled="fieldsDisabled"
                      @input="$v.email.$touch()"
                      @blur="$v.email.$touch()"
                    />
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col
                    cols="10"
                    class="mx-2"
                  >
                    <v-text-field
                      id="password"
                      v-model="password"
                      :error-messages="passwordErrors"
                      type="password"
                      required
                      placeholder="password"
                      :disabled="fieldsDisabled"
                      @input="$v.password.$touch()"
                      @blur="$v.password.$touch()"
                    />
                  </v-col>
                </v-row>
                <v-row justify="center">
                  <v-col
                    cols="10"
                    class="mx-2"
                  >
                    <v-btn
                      type="submit"
                      variant="primary"
                      :disabled="buttonDisabled"
                    >
                      <span
                        v-if="loading"
                        class="custom-loader"
                      >
                        <v-progress-circular
                          indeterminate
                          color="primary"
                        />
                      </span>
                      <span v-else>Login</span>
                    </v-btn>
                  </v-col>
                </v-row>
                <br>
                <v-alert
                  v-if="dismissCountDown"
                  dismissible
                  transition="fade-transition"
                  type="error"
                >
                  {{ message }}
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
  </v-app>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required, email } from 'vuelidate/lib/validators'
  const config = require('@/config')
  const axios = require('axios')
  const qs = require('querystring')

  export default {
    name: 'Login',
    components: {},
    mixins: [validationMixin],
    validations: {
      password: { required },
      email: { required, email },
      checkbox: {
        checked (val) {
          return val
        },
      },
    },
    data: () => ({
      email: '',
      password: '',
      dismissSecs: 3,
      dismissCountDown: 0,
      message: '',
      loading: false,
      buttonDisabled: true,
      fieldsDisabled: false,
      risposta: '',
    }),
    computed: {
      emailErrors () {
        const errors = []
        var component = this
        if (!this.$v.email.$dirty) return errors
        !this.$v.email.email && errors.push('E-mail non valida')
        !this.$v.email.required && errors.push('E-mail necessaria')
        if (this.$v.email.email && this.$v.email.required && this.$v.password.required) {
          component.buttonDisabled = false
        } else {
          component.buttonDisabled = true
        }
        return errors
      },
      passwordErrors () {
        const errors = []
        var component = this
        if (!this.$v.password.$dirty) return errors
        !this.$v.password.required && errors.push('Password necessaria')
        if (this.$v.email.email && this.$v.email.required && this.$v.password.required) {
          component.buttonDisabled = false
        } else {
          component.buttonDisabled = true
        }
        return errors
      },
    },
    created: function () {},
    methods: {
      onSubmit (evt) {
        const component = this
        evt.preventDefault()
        component.loading = true
        component.buttonDisabled = true
        component.fieldsDisabled = true
        // console.log(this.form)
        var parameters = { username: this.email, password: this.password }
        // console.log(parameters)
        axios.post(config.backendURL + '/adminlogin', qs.stringify(parameters), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }).then(function (response) {
          // get status
          console.log(response.status)

          // get body data
          console.log(response)
          component.risposta = response.data
          // console.log(response.body)

          if (response.data.httpStatus === 200) {
            // emit the auth event that will be listened by other components
            localStorage.setItem(config.authToken, response.data.jwt)
            component.$router.push({ path: '/' })
          } else {
            component.dismissCountDown = 4
            component.message = response.data.longMessage
            setTimeout(() => {
              component.dismissCountDown = 0
            }, 4000)
          }
          component.loading = false
          component.buttonDisabled = false
          component.fieldsDisabled = false
        }).catch(function (response) {
          // error callback
          component.risposta = response
          component.message = response
          component.dismissCountDown = 4
          component.loading = false
          component.buttonDisabled = false
          component.fieldsDisabled = false
        })
      },
      onReset (evt) {
        evt.preventDefault()
        // Reset our form values
        this.email = ''
        this.password = ''
      },
      countDownChanged (dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert () {
        this.dismissCountDown = this.dismissSecs
      },
    },
  }
</script>
